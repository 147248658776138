import React from "react"
import { Helmet } from "react-helmet"
import { Grid } from "@material-ui/core"


export const Fees = () => (
  <main>
  <div>
    <Helmet>
    <title>
     Pay Fees Online | Cambridge International Foundation School 

         </title>
    </Helmet>

    </div>
   <Grid container justify="center" style={{ margin: "10px 2px" }}>
        <div
          className="teamContainer pageContainer pageContainer1"
          style={{ marginBottom: "30px" }}
        >
      <h1
        style={{
          color: "#e95721",
          fontSize: "35px",
          fontWeight: "bold",
          fontFamily: "sans-serif",
        }}
      >
        Pay Fees Online
      </h1>

      <p className="card-text text-secondary">
        To pay fees online kindly login to Schoolpad and pay fee.
        Follow the URL below to proceed to Schoolpad login.
        <br /><br />
        <strong>URL for Schoolpad login – </strong>
        <a href="https://cisfjalandhar.schoolpad.in/loginManager/load">https://cisfjalandhar.schoolpad.in/loginManager/load</a>
        <br /></p>
              <p>
          <i style={{ fontSize: "110%", color: "#0ca685" }}>
            For any clarifications regarding fee, kindly visit school.
          </i>
        </p>

    </div>
    </Grid>
  </main>
)
